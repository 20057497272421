.login {
  background-image: linear-gradient(to bottom right, #2c2e34, #242a34);
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}

.login__logo {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login > button {
  background-color: #738adb;
  color: white;
  font-weight: 800;
  width: 300px;
}

.login > button:hover {
  border: 1px solid #738adb;
  color: #738adb;
}

.login__logo > h1 {
  color: white;
  font-family: monospace;
  padding-top: 20px;
  padding-left: 3px;
  font-size: 40px;
  text-shadow: 3px 1px #738adb;
}
