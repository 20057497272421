.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.25;
  height: 100vh;
  background-color: #2f3135;
}

.sidebar__top {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  background-color: #2f3135;
  padding: 20px;
  border-bottom: 4px solid #26282c;
}

.sidebar__logout {
  display: flex;
  justify-content: flex-end;

  align-items: center;
  background-color: #2f3135;
  padding: 20px;
}

.sidebar__logout > button {
  color: white;
  border-color: white;
}

.sidebar__top > .MuiSvgIcon-root {
  cursor: pointer;
}
.sidebar__channels {
  flex: 1;
  color: grey;
}
.sidebar__addChannel {
  cursor: pointer;
}

.sidebar__addChannel:hover {
  color: white;
}

.sidebar__channelsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.sidebar__header {
  display: flex;
  justify-content: center;
  align-items: center;
}
